import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



export const Tours = (props) => {
  return (
    <div id="tours" className="text-center">
      <div className="container">
        <div className="section-title">
          <h1>Our Tours</h1>
          <h2>
            Experience Goa's Vibrant Spirit: Your Unforgettable Journey Awaits!
          </h2>
          <h3>
            North Goa Tour
          </h3>
          <a
                  href="#contact"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  BOOK NOW
                </a>{""}
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3">
                {" "}
                
                  <img src={d.img}   alt="" />
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>

        
      </div>
    </div>
  );
};
