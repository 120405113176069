import React from "react";
import {View, Image} from 'react';
import {FontAwesomeIcon} from 'react';
import { IconName } from "react-icons";

export const SouthTours = (props) => {
  return (
    <div id="southtours" className="text-center">
      <div className="container">
        <div className="section-title">
          
          <h3>
            South Goa Tour
          </h3>
          <a
                  href="#contact"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  BOOK NOW
                </a>{""}
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3">
                  {" "}
                  <img src={d.img}   alt="" />
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>

        
      </div>
    </div>
  );
};
